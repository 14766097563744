import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import VisuallyHidden from '@reach/visually-hidden';

import { themeProps, Box, UnstyledButton, Text } from 'components/design-system';
import { logEventClick } from 'utils/analytics';
import useDarkMode from 'utils/useDarkMode';

import Logo from './Logo';
import {
  NavGrid,
  MainNavInner,
  MainNavCenter,
  SecondaryNavWrapper,
  SecondaryNavLink,
  FadeBox,
  MainNavLink,
  MobileNav,
  MobileNavLink,
  MainNavCenterLinks,
  MainNavRight,
} from './components';
import OptionModal from './OptionModal';
import {
  HomeIcon,
  InformationIcon,
  // GuideIcon,
  OptionIcon,
  ChevronIcon,
  SearchIcon,
  DialogIcon,
  SyringeIcon,
  // CaseIcon,
} from '../../icons';

import SearchModal from '../../../modules/core/SearchModal';

interface NavigationProps {
  pageTitle?: string;
}

const Root = Box.withComponent('header');

const LogoLinkRoot = Box.withComponent('a');

const LogoLink = styled(LogoLinkRoot)`
  display: block;
  width: 56px;
  height: 48px;
  overflow: hidden;

  ${themeProps.mediaQueries.md} {
    width: 80px;
    height: 64px;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
  }
`;

const LogoWrapper = styled(Box)`
  > svg {
    ${themeProps.mediaQueries.md} {
      width: 80px !important;
      height: 64px !important;
    }
  }
`;

const ColorToggleWrapper = styled(Box)`
  display: none;
  justify-content: space-between;
  align-items: center;

  ${themeProps.mediaQueries.sm} {
    display: flex;
  }
`;

const ToggleButtonText = styled(Text)`
  display: none;

  ${themeProps.mediaQueries.lg} {
    display: block;
    white-space: nowrap;
  }
`;

const ToggleButtonInnerWrapper = styled.div`
  display: flex;
  width: 134px;
  height: 32px;
  font-weight: bold;
  line-height: 1;
`;

const ToggleButtonLight = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: ${themeProps.space.sm}px;
  padding-right: ${themeProps.space.xs}px;
  border-radius: 16px 0 0 16px;
`;

const ToggleButtonDark = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: ${themeProps.space.xs}px;
  padding-right: ${themeProps.space.sm}px;
  border-radius: 0 16px 16px 0;
`;

const SearchButton = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  outline: none;
`;

const OptionButton = styled(UnstyledButton)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-bottom: 2px;
`;

const OptionButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 4px 0;
  margin-bottom: 2px;
`;

const PageTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${themeProps.mediaQueries.sm} {
    display: none;
  }
`;

const Navigation: React.FC<NavigationProps> = ({ pageTitle }) => {
  const [isDarkMode, toggleDarkMode] = useDarkMode();
  const [isSearchModalOpen, setIsSearchModalOpen] = React.useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = React.useState(false);
  const router = useRouter();

  const navGridBgColor = 'card';

  const getMobNavIconColor = (activePath?: string) =>
    isDarkMode || router.pathname === activePath
      ? themeProps.colors.foreground
      : themeProps.colors.background;

  const toggleSearchModal = () => {
    setIsSearchModalOpen(!isSearchModalOpen);
  };

  const toggleOptionModal = () => {
    setIsOptionModalOpen(!isOptionModalOpen);
  };

  return (
    <Root>
      <NavGrid backgroundColor={router.pathname === '/' ? 'transparent' : navGridBgColor}>
        <MainNavInner>
          <Link href="/" passHref>
            <LogoLink onClick={() => logEventClick('Beranda')}>
              <VisuallyHidden>Kawal COVID-19</VisuallyHidden>
              <LogoWrapper display="flex" alignItems="flex-end">
                <Logo aria-hidden />
              </LogoWrapper>
            </LogoLink>
          </Link>
          <MainNavCenter flex="1 1 auto">
            {pageTitle && (
              <PageTitle>
                <Box display={router.pathname === '/' ? 'none' : 'block'} mr="xs">
                  <ChevronIcon fill={themeProps.colors.accents07} />
                </Box>
                <Text>{pageTitle}</Text>
              </PageTitle>
            )}
            <MainNavCenterLinks>
              <MainNavLink href="/" title="Beranda" isActive={router.pathname === '/'} />
              <MainNavLink
                href="/indeks-kewaspadaan"
                title="Indeks Kewaspadaan"
                isActive={router.pathname === '/indeks-kewaspadaan'}
              />
              <MainNavLink
                href="/category/[slug]"
                as="/category/artikel"
                title="Informasi"
                isActive={['/category/[slug]', '/faq', '/tentang-kami'].includes(router.pathname)}
              />
              {/* <Link href="/" passHref>
                <MainNavLink>Kasus</MainNavLink>
              </Link> */}
              {/* <Link href="/category/[slug]" as="/category/panduan" passHref>
                  <MainNavLink
                    isActive={
                      router.pathname === '/category/[slug]' && router.asPath === '/category/panduan'
                    }
                  >
                    Panduan
                  </MainNavLink>
                </Link> */}
              {/* <Link href="/" passHref>
                <MainNavLink>Lainnya</MainNavLink>
              </Link> */}
              {/* TODO: Reenable later when AWS migration has been completed
              <MainNavLink href="/kasus" title="Kasus" isActive={router.pathname === '/kasus'} /> */}
              <MainNavLink
                href="/vaksin"
                title="Vaksin"
                isActive={
                  router.pathname === '/vaksin' ||
                  router.pathname === '/vaksin/kenali' ||
                  router.pathname === '/vaksin/cara-registrasi'
                }
              />
              <MainNavLink
                href="/publikasi"
                title="Publikasi"
                isActive={router.pathname === '/publikasi'}
              />
              <MainNavLink
                href="/unduh-aplikasi"
                title="Unduh Aplikasi"
                isActive={router.pathname === '/unduh-aplikasi'}
              />
              {/* <MainNavLink href="https://berbagibantuan.kawalcovid19.id" title="Berbagi Bantuan" /> */}
            </MainNavCenterLinks>
          </MainNavCenter>
          <MainNavRight display="flex" alignItems="center">
            <ColorToggleWrapper>
              <ToggleButtonText variant={200} mr="sm">
                Mode warna
              </ToggleButtonText>
              <UnstyledButton style={{ outline: 'none' }} onClick={toggleDarkMode}>
                <ToggleButtonInnerWrapper>
                  <ToggleButtonLight backgroundColor="buttonlightmode" color="buttonlightmodetext">
                    <Text variant={200}>Terang</Text>
                  </ToggleButtonLight>
                  <ToggleButtonDark backgroundColor="buttondarkmode" color="buttondarkmodetext">
                    <Text variant={200}>Gelap</Text>
                  </ToggleButtonDark>
                </ToggleButtonInnerWrapper>
              </UnstyledButton>
            </ColorToggleWrapper>
            <SearchButton
              type="button"
              backgroundColor="background"
              onClick={toggleSearchModal}
              display={['none', null, 'flex', null, null]}
              ml="md"
            >
              <VisuallyHidden>Pencarian</VisuallyHidden>
              <SearchIcon fill={getMobNavIconColor()} aria-hidden />
            </SearchButton>
          </MainNavRight>
        </MainNavInner>
      </NavGrid>
      <SecondaryNavWrapper
        backgroundColor={navGridBgColor}
        color="foreground"
        display={
          router.pathname === '/category/[slug]' ||
          router.pathname === '/tentang-kami' ||
          router.pathname === '/faq'
            ? 'flex'
            : 'none'
        }
      >
        <NavGrid flex="1 1 auto" backgroundColor={navGridBgColor} color="foreground">
          <Box display="flex" flex="1 1 auto" gridColumn="3/4">
            <FadeBox left={0} direction="to left" color={navGridBgColor} />
            <FadeBox right={0} direction="to right" color={navGridBgColor} />
            <Box as="nav" display="flex" flexDirection="row" overflowX="auto" overflowY="hidden">
              <SecondaryNavLink
                href="/category/[slug]"
                as="/category/artikel"
                isActive={router.query.slug === 'artikel'}
                title="Artikel"
              />
              <SecondaryNavLink
                href="/category/[slug]"
                as="/category/panduan"
                isActive={router.query.slug === 'panduan'}
                title="Panduan"
              />
              <SecondaryNavLink
                href="/category/[slug]"
                as="/category/bacaan"
                isActive={router.query.slug === 'bacaan'}
                title="Bacaan Pilihan"
              />
              <SecondaryNavLink
                href="/category/[slug]"
                as="/category/verifikasi"
                isActive={router.query.slug === 'verifikasi'}
                title="Periksa Fakta"
              />
              <SecondaryNavLink
                href="/category/[slug]"
                as="/category/checklist"
                isActive={router.query.slug === 'checklist'}
                title="Check-list"
              />
              <SecondaryNavLink
                href="/category/[slug]"
                as="/category/infografik"
                isActive={router.query.slug === 'infografik'}
                title="Infografik"
              />
              <SecondaryNavLink
                href="/tentang-kami"
                isActive={router.pathname === '/tentang-kami'}
                title="Tentang Kami"
              />
              <SecondaryNavLink href="/faq" isActive={router.pathname === '/faq'} title="FAQ" />
            </Box>
          </Box>
        </NavGrid>
      </SecondaryNavWrapper>
      <SecondaryNavWrapper
        backgroundColor={navGridBgColor}
        color="foreground"
        display={
          router.pathname === '/vaksin' ||
          router.pathname === '/vaksin/kenali' ||
          router.pathname === '/vaksin/cara-registrasi'
            ? 'flex'
            : 'none'
        }
      >
        <NavGrid flex="1 1 auto" backgroundColor={navGridBgColor} color="foreground">
          <Box display="flex" flex="1 1 auto" gridColumn="3/4">
            <FadeBox left={0} direction="to left" color={navGridBgColor} />
            <FadeBox right={0} direction="to right" color={navGridBgColor} />
            <Box as="nav" display="flex" flexDirection="row" overflowX="auto" overflowY="hidden">
              <SecondaryNavLink
                href="/vaksin"
                as="/vaksin"
                isActive={router.pathname === '/vaksin'}
                title="Situasi"
              />
              <SecondaryNavLink
                href="/vaksin/kenali"
                as="/vaksin/kenali"
                isActive={router.pathname === '/vaksin/kenali'}
                title="Kenali"
              />

              {/*
                TODO : Wordpress content
              <SecondaryNavLink
                href="/vaksin/cara-registrasi"
                as="/vaksin/cara-registrasi"
                isActive={router.pathname === '/vaksin/cara-registrasi'}
                title="Cara Registrasi Vaksinasi"
              /> */}
            </Box>
          </Box>
        </NavGrid>
      </SecondaryNavWrapper>
      <NavGrid backgroundColor="accents02" color="foreground">
        <MobileNav backgroundColor="navgridbgmobile">
          <MobileNavLink
            href="/"
            isActive={router.pathname === '/'}
            title="Beranda"
            icon={<HomeIcon fill={getMobNavIconColor('/')} />}
          />
          <MobileNavLink
            href="/indeks-kewaspadaan"
            isActive={router.pathname === '/indeks-kewaspadaan'}
            title="Indeks Kewaspadaan"
            icon={<DialogIcon fill={getMobNavIconColor('/faq')} />}
          />
          <MobileNavLink
            href="/category/[slug]"
            as="/category/artikel"
            isActive={router.pathname === '/category/[slug]'}
            title="Informasi"
            icon={<InformationIcon fill={getMobNavIconColor('/category/[slug]')} />}
          />
          <MobileNavLink
            href="/vaksin"
            as="/vaksin"
            isActive={router.pathname === '/vaksin' || router.pathname === '/vaksin/kenali'}
            title="Vaksin"
            icon={<SyringeIcon fill={getMobNavIconColor('/vaksin' || '/vaksin/kenali')} />}
          />
          {/* TODO: Reenable later when AWS migration has been completed
          <MobileNavLink
            href="/kasus"
            isActive={router.pathname === '/kasus'}
            title="Kasus"
            icon={<CaseIcon fill={getMobNavIconColor('/kasus')} />}
          /> */}
          {/* <NavLinkMobile
            href="/ongoing"
            isActive={router.pathname === '/category/[slug]'}
            title="Panduan"
            icon={<GuideIcon fill={getMobNavIconColor('/category/[slug]')} />}
          /> */}
          <OptionButton type="button" style={{ outline: 'none' }} onClick={toggleOptionModal}>
            <OptionButtonIcon>
              <OptionIcon fill={getMobNavIconColor()} />
            </OptionButtonIcon>
            <Text variant={100}>Lainnya</Text>
          </OptionButton>
        </MobileNav>
      </NavGrid>
      <SearchModal isOpen={isSearchModalOpen} onClose={toggleSearchModal} />
      <OptionModal isOpen={isOptionModalOpen} onClose={toggleOptionModal} />
    </Root>
  );
};

export default Navigation;
